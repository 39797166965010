<template>
    <div class="container">
        <div class="row align-items-center min-vh-100 w-100">
            <div class="col-12">
                <div class="row justify-content-center">
                    <div class="col-md-6 col-xl-4">
                        <h1 class="mb-5 pt-3 text-center"><strong>ProTrader</strong></h1>
                        <form @submit.prevent="login"
                              novalidate
                              v-if="user"
                              class="card-body pb-3">
                            <h4 class="card-title">Log in</h4>
                            <AppInput v-model="user.email"
                                      label="Email"
                                      placeholder="Email"
                                      field="email"
                                      :errors="errors"
                                      ref="emailInput"
                                      class="mb-3"/>
                            <AppInput v-model="user.password"
                                      label="Password"
                                      placeholder="Password"
                                      type="password"
                                      field="password"
                                      :errors="errors"
                                      class="mb-3">
                            </AppInput>
                            <div class="d-grid">
                                <button class="btn btn-lg btn-primary shadow-sm"
                                        type="submit"
                                        :disabled="submitted">
                                    Log in
                                </button>
                            </div>
                        </form>
                        <div class="card-body pt-0">
                            <p class="text-center mt-3">
                                <router-link class="btn btn-sm btn-link"
                                             :to="{name: 'request-password-reset'}">
                                    Can't log in?
                                </router-link>
                                <!--                <span>&#8226;</span>
                                                <router-link class="btn btn-sm btn-link"
                                                             :to="{name: 'signup'}">
                                                  Sign up for an account
                                                </router-link>-->
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import AppInput from '@/components/form/AppInput';
import NotificationService from '@/services/notification.service';
import Errors from '@/utils/errors';

export default {
  components: {
    AppInput,
  },
  data() {
    return {
      errors: new Errors(),
      submitted: false,
      user: null,
    };
  },
  mounted() {
    this.resetUser();
    this.$nextTick(() => this.$refs.emailInput.focus());
  },
  methods: {
    resetUser() {
      this.user = {
        email: '',
        password: '',
      };
    },
    login() {
      this.errors.clear();
      this.submitted = true;
      this.$store.dispatch('auth/login', this.user)
        .then(() => {
          this.resetUser();
          NotificationService.success('Welcome Pro Trader!');
        })
        // .catch((err) => this.errors.set(err.response.data.errors))
        .finally(() => {
          this.submitted = false;
        });
    },
  },
};
</script>
